import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  CancelTokenSource,
} from "axios";
import { AppDispatch } from "../store/store";

// Define the expected type for the error response data
interface ErrorResponseData {
  message: string;
  // add other properties as needed
}

// Create an array to store cancel tokens for active requests
let cancelTokens: CancelTokenSource[] = [];

const createApiInstance = (dispatch: AppDispatch): AxiosInstance => {
  const navigate = () => {
    const pathArray = window.location.pathname.split("/");
    if (pathArray[1] === "admin") {
      window.location.href = "/admin/login";
    } else {
      window.location.href = "/";
    }
  };

  const Api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: true,
  });

  // Request interceptor
  Api.interceptors.request.use(
    (config) => {
      // Create a new cancel token for the request
      const source = axios.CancelToken.source();
      config.cancelToken = source.token;

      // Store the cancel token for future cancellation
      cancelTokens.push(source);

      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response interceptor
  Api.interceptors.response.use(
    (response: AxiosResponse) => {
      // Remove the cancel token from the list after a successful request
      cancelTokens = cancelTokens.filter(
        (source) => source.token !== response.config.cancelToken
      );
      return response;
    },
    (error: AxiosError) => {
      // if (error.code === "ERR_NETWORK") {
      //   alert("Something went wrong...");
      //   navigate();
      // }

      if (error.response && error.response.status === 401) {
        // Cancel all requests
        cancelTokens.forEach((source) => {
          source.cancel("Canceled due to 401 Unauthorized");
        });
        cancelTokens = []; // Clear the list of cancel tokens

        const responseData = error.response.data as ErrorResponseData;
        if (responseData.message) {
          alert(responseData.message);
        }
        navigate();
      }

      // Clean up the token list on error
      cancelTokens = cancelTokens.filter(
        (source) =>
          error && error.config && source.token !== error.config.cancelToken
      );

      if (error.response && error.response.data) {
        return Promise.reject(error.response);
      }

      return Promise.reject(error.message);
    }
  );

  return Api;
};

export default createApiInstance;
