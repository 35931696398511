import { useCallback, useEffect, useRef } from "react";
import { Box } from "@mui/material";
import DashboardStyle from "./DashboardStyle";
import QuickReferences from "./QuickReferences/QuickReferences";
import WelcomeCard from "./WelcomeCard/WelcomeCard";
import Policies from "./Policies/Policies";
import Claims from "./Claims/Claims";
import { RootState } from "../../store/store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { GetPoliciesData, getClaimsData } from "./DashboardAction";
import Alerts from "./Alerts/Alerts";
import { getBufferData, isFeatureAvailable } from "../../utils/Utility";
import { setDownloadIdCardContent } from "./DashboardSlice";
import { Api } from "../..";
import { IClaim } from "../../models/user/dashboard/Dashboard";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const Dashboard = () => {
  const dispatch = useAppDispatch();

  const { userDetails } = useAppSelector((state: RootState) => state.userAuth);
  const { isError, isSuccess, calledReducerType } = useAppSelector(
    (store: RootState) => store.common
  );
  const { downloadIdCardContent, claimsData } = useAppSelector(
    (state: RootState) => state.dashboard
  );
  const { features } = useAppSelector((state: RootState) => state.features);

  const policiesRef = useRef<HTMLDivElement | null>(null);

  const handleYourPoliciesClick = (): void => {
    policiesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getInitBasePrompt = useCallback(async () => {
    try {
      await Api.get(`ia/${userDetails?.customerId}/init`);
    } catch (error) {
      console.log(error);
    }
  }, [userDetails?.customerId]);

  useEffect(() => {
    if (userDetails && userDetails.customerId) {
      dispatch(GetPoliciesData({ customerId: userDetails?.customerId }));
    }
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (
      userDetails &&
      userDetails.customerId &&
      (isSuccess || isError) &&
      calledReducerType === "dashboard/GetPoliciesData"
    ) {
      dispatch(getClaimsData({ customerId: userDetails.customerId }))
        .then(() => {
          getInitBasePrompt();
        })
        .catch((error) => {
          getInitBasePrompt();
        });
    }
  }, [
    userDetails,
    isSuccess,
    calledReducerType,
    isError,
    dispatch,
    getInitBasePrompt,
  ]);

  useEffect(() => {
    if (
      isSuccess &&
      calledReducerType === "GetDownloadIdCard" &&
      downloadIdCardContent
    ) {
      const bufferArray = getBufferData(downloadIdCardContent);
      const url = URL.createObjectURL(
        new Blob([bufferArray], { type: "application/pdf" })
      );
      const downloadLink = document.createElement("a");
      downloadLink.download = "Insured ID card.pdf";
      downloadLink.href = url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      dispatch(setDownloadIdCardContent(null));
    }
  }, [calledReducerType, isSuccess, downloadIdCardContent, dispatch]);

  useEffect(() => {
    if (isError && calledReducerType === "GetDownloadIdCard") {
      alert(`Error in downloading document`);
    }
  }, [calledReducerType, isError]);

  // console.log(
  //   isFeatureAvailable({
  //     featureCodes: ["lob_pa_claims_my_claims,lob_pa_claims"],
  //     features,
  //   })
  // );

  return (
    <Box sx={DashboardStyle.dashboard}>
      <WelcomeCard handleYourPoliciesClick={handleYourPoliciesClick} />
      <Alerts />
      {isFeatureAvailable({
        featureCodes: ["lob_pa_claims_my_claims"],
        features,
      }) &&
      claimsData &&
      claimsData.claimList &&
      claimsData.claimList.filter(
        (claim: IClaim) => claim.carrierClaimStatus !== "Closed"
      ).length > 0 ? (
        <Claims claimsData={claimsData} />
      ) : (
        <></>
      )}
      {isFeatureAvailable({ features, featureCodes: ["lob_pa_policies"] }) ? (
        <Box ref={policiesRef}>
          <Policies />
        </Box>
      ) : (
        <></>
      )}
      {isFeatureAvailable({ features, featureCodes: ["quick_references"] }) ? (
        <QuickReferences />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default Dashboard;
