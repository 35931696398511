import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Typography,
  useMediaQuery,
} from "@mui/material";
import BasicInfoCardQuickActionsStyles from "./BasicInfoCardQuickActionsStyles";
import ContactButtonComponent from "../QuickActions/ContactButtonComponent";
import AutoPaymentComponent from "../QuickActions/AutoPaymentComponent";
import FileAClaim from "../../../../components/FileAClaim/FileAClaim";
import { useParams } from "react-router-dom";
import { IDownloadIdCardParams } from "../../../../models/user/dashboard/Dashboard";
import { GetDownloadIdCard } from "../../../Dashboard/DashboardAction";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { isFeatureAvailable } from "../../../../utils/Utility";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface IProps {
  handlePayment: () => void;
}

const BasicInfoCardQuickActions: React.FC<IProps> = ({ handlePayment }) => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const isTablet = useMediaQuery("(min-width:769px) and (max-width:1200px)");

  const { policyId } = useParams();

  const dispatch = useAppDispatch();

  const { features } = useAppSelector((state) => state.features);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedAction, setSelectedAction] = useState<string>("");
  const [fileAClaimOpen, setFileAClaimOpen] = useState<boolean>(false);

  const buttons = useMemo(() => {
    return [
      "File A Claim",
      "Contact",
      "Pay",
      "Download ID",
      "Roadside Assistance",
    ]
      .filter((button) => {
        switch (button) {
          case "Pay":
            return isFeatureAvailable({
              featureCodes: ["lob_pa_billing_payments"],
              features,
            });
          case "Download ID":
            return isFeatureAvailable({
              featureCodes: ["lob_pa_policies_insurance_id"],
              features,
            });
          case "File A Claim":
            return isFeatureAvailable({
              featureCodes: ["lob_pa_claims_new_claim"],
              features,
            });
          case "Roadside Assistance":
            return isFeatureAvailable({
              featureCodes: ["roadside_assistance"],
              features,
            });
          default:
            return true;
        }
      })
      .slice(0, isMobile ? 1 : 5);
  }, [features, isMobile]);

  const filterMoreOptions = useCallback(
    (options: string[]): string[] => {
      return options.filter((option) => {
        switch (option) {
          case "Cancel Policy":
            return isFeatureAvailable({
              featureCodes: ["lob_pa_policies_cancel_policy"],
              features,
            });
          case "Download ID":
            return isFeatureAvailable({
              featureCodes: ["lob_pa_policies_insurance_id"],
              features,
            });
          case "Pay":
            return isFeatureAvailable({
              featureCodes: ["lob_pa_billing_payments"],
              features,
            });
          case "Road Side Assistance":
            return isFeatureAvailable({
              featureCodes: ["roadside_assistance"],
              features,
            });
          default:
            return true;
        }
      });
    },
    [features]
  );

  const moreOptions = useMemo(
    (): string[] =>
      isMobile
        ? filterMoreOptions([
            "Contact",
            "Pay",
            "Download ID",
            "Roadside Assistance",
          ])
        : filterMoreOptions(["Road Side Assistance"]),
    [isMobile, filterMoreOptions]
  );

  const open = Boolean(anchorEl);

  const handleClick = ({
    event,
    text,
  }: {
    event?: React.MouseEvent<HTMLButtonElement>;
    text: string;
  }) => {
    setSelectedAction(text);
    if (event) {
      setAnchorEl(event.currentTarget);
    }
    if (text === "File A Claim") {
      setFileAClaimOpen(true);
    }
    if (text === "Download ID") {
      handleDownloadIdCard();
    }
    if (text === "Pay") {
      handlePayment();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedAction("");
  };

  const renderPopoverContent = () => {
    switch (selectedAction) {
      case "File A Claim":
        return <FileAClaim open={fileAClaimOpen} setOpen={setFileAClaimOpen} />;
      case "Contact":
        return (
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <ContactButtonComponent />
          </Popover>
        );
      case "Enable Auto Pay":
        return <AutoPaymentComponent onClose={handleClose} open={open} />;
      case "More":
        return (
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {moreOptions.map((option) => (
              <MenuItem
                key={option}
                onClick={(event) => handleClick({ text: option })}
                sx={{
                  color: "#1D252B",
                  fontFamily: "Noto Sans",
                  fontStyle: "normal",
                  fontWeight: 300,
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        );
      default:
        return null;
    }
  };

  const handleDownloadIdCard = () => {
    if (!policyId) {
      return;
    }
    const requestParams: IDownloadIdCardParams = {
      policyId,
    };
    dispatch(GetDownloadIdCard(requestParams));
  };

  useEffect(() => {
    if (isMobile || isTablet) {
      setAnchorEl(null);
      setSelectedAction("");
    }
  }, [isMobile, isTablet]);

  return (
    <Box sx={BasicInfoCardQuickActionsStyles.BasicInfoQuickActionContainer}>
      {isMobile ? (
        <>
          {buttons.map((text, index) => (
            <Button
              variant="outlined"
              key={`${text}_${index}`}
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => handleClick({ event, text })}
              sx={{ width: "max-content" }}
            >
              {text}
            </Button>
          ))}
          <IconButton onClick={(event) => handleClick({ event, text: "More" })}>
            <MoreVertIcon sx={{ fontSize: "2rem" }} />
          </IconButton>
          {renderPopoverContent()}
        </>
      ) : (
        <>
          {isTablet ? (
            <>
              {buttons.map((text, index) => (
                <Button
                  variant="outlined"
                  sx={{ width: "max-content" }}
                  key={`${text}_${index}`}
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => handleClick({ event, text })}
                >
                  {text}
                </Button>
              ))}
              {/* <IconButton
                  onClick={(event) => handleClick({ event, text: "More" })}
                >
                  <MoreVertIcon
                    sx={{
                      color: theme.palette.secondary.main,
                      fontSize: "2rem",
                    }}
                  />
                </IconButton> */}
              {renderPopoverContent()}
            </>
          ) : (
            <>
              <Typography sx={BasicInfoCardQuickActionsStyles.quickStyle}>
                {"Quick Actions"}
              </Typography>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "10px",
                }}
              >
                {buttons.map((text, index) => (
                  <Button
                    variant="outlined"
                    sx={{ width: "100%" }}
                    key={`${text}_${index}`}
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => handleClick({ event, text })}
                  >
                    {text}
                  </Button>
                ))}
              </Box>
              {renderPopoverContent()}
            </>
          )}
        </>
      )}
    </Box>
  );
};

export default BasicInfoCardQuickActions;
