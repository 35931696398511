const paymentStyle = {
  mainBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    textAlign: "center",
    margin: "auto",
    boxShadow: "0px 6px 10px 0px rgba(63, 63, 63)",
    padding: "1.5rem",
    borderRadius: "5px",
    backgroundColor: "#ffffff",
    "@media (max-width: 1024px)": {
      width: "80%",
    },
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  textFont: {
    fontSize: "14px",
  },
  fieldSec: {
    display: "flex",
    width: "100%",
    marginBottom: "1rem",
    "@media (max-width: 1024px)": {
      flexDirection: "column",
      alignItems: "center",
    },
    "@media (max-width: 768px)": {
      marginBottom: "0.5rem",
    },
  },
  fieldLabel: {
    display: "flex",
    alignItems: "center",
    fontSize: "1rem",
    marginLeft: "5rem",
    flex: "1",
    "@media (max-width: 1024px)": {
      fontSize: "0.9rem",
      marginRight: "6rem",
      textAlign: "center",
      justifyContent: "center",
      marginBottom: "0.5rem",
    },
    "@media (max-width: 768px)": {
      fontSize: "0.8rem",
      textAlign: "center",
      justifyContent: "center",
      width: "100%",
    },
  },
  paySection: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "@media (max-width: 1024px)": {
      flexDirection: "column",
    },
  },
  amountValue: {
    marginRight: "2rem",
    "@media (max-width: 1024px)": {
      marginRight: "0",
    },
  },
  textAmountSection: {
    display: "inline-flex",
    flexDirection: "row",
    width: "100%",
    margin: "1rem",
    "@media (max-width: 1024px)": {
      flexDirection: "column",
      alignItems: "center",
      margin: "1rem 0",
    },
  },
  amountLabel: {
    marginLeft: "5rem",
    "@media (max-width: 1024px)": {
      marginLeft: "3rem",
    },
    "@media (max-width:768px)": {
      marginLeft: "3rem",
    },
  },
  amountLabelBox: {
    marginRight: "2.5rem",
    "@media (max-width: 1024px)": {
      marginRight: "3rem",
    },
  },
  amountAmtBox: {
    marginLeft: "4rem",
    "@media (max-width: 1024px)": {
      marginLeft: "0",
      textAlign: "center",
    },
  },
  amt: {
    wordWrap: "break-word",
    fontWeight: "bold",
    textAlign: "left",
    "@media (max-width: 1024px)": {
      textAlign: "center",
    },
  },
  btnBox: {
    marginTop: "1rem",
    marginBottom: "1rem",
    marginLeft: "1.5rem",
  },
  payBtn: {
    textTransform: "none",
    padding: "12px 12px",
  },
  pSuccesMain: {
    width: "100%",
    height: "84.5vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
  },
  pSuccessMai2: {
    padding: "1rem",
    backgroundColor: "#ffffff",
    width: "45%",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Noto Sans",
    boxShadow: "0px 6px 10px 0px rgba(63, 63, 63)",
    borderRadius: "5px",
    "@media (max-width: 1024px)": {
      width: "70%",
    },
    "@media (max-width: 768px)": {
      width: "90%",
    },
  },
  pSuccessMsgSec: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pSuccessIcon: {
    color: "green",
    marginRight: "1rem",
    marginTop: "1rem",
    alignItems: "center",
  },
  pSuccessMsg: {
    textAlign: "center",
    fontSize: "1.5rem",
    color: "green",
    wordWrap: "break-word",
  },
  pSuccessSecBox: {
    width: "100%",
    display: "inline-flex",
    padding: "0 1rem 0 1rem",
    "@media (max-width:768px)": {
      marginTop: "5px",
      alignItems: "center",
    },
  },
  pFailIcon: {
    color: "red",
  },
  pFailMsg: {
    fontSize: "1.5rem",
    color: "red",
    wordWrap: "break-word",
  },
  transactionId: {
    wordWrap: "break-word",
    fontSize: "14px",
  },
};

export default paymentStyle;
