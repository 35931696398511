import {
  Box,
  SelectChangeEvent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HeadingText from "../../common/HeadingText/HeadingText";
import BasicTabs from "../../common/BasicTabs/BasicTabs";
import DocumentListingCards from "../DocumentsListingCards/DocumentListingCards";
import ImageAndText from "../../common/ImageAndText/ImageAndText";
import searchIcon from "../../assets/svgs/search-icon.svg";
import filterIcon from "../../assets/svgs/filter-icon.svg";
import cardView from "../../assets/svgs/card-view-icon.svg";
import listView from "../../assets/svgs/list-view-icon.svg";
import TabSwitch from "../../common/TabSwitch/TabSwitch";
import addnewIcon from "../../assets/svgs/add-new-icon.svg";
import cardViewBlack from "../../assets/svgs/card-view-black-icon.svg";
import listViewWhite from "../../assets/svgs/list-view-white-icon.svg";
import {
  ADD_NEW,
  CARD_VIEW,
  DOCUMNET_LISTING,
  FILTER,
  LIST_VIEW,
  SEARCH,
  TAB1,
  TAB2,
} from "../../constants";
import { DocumnetListingStyles } from "./DocumentListingStyles";
import CustomButton from "../../common/CustomButton/CustomButton";
import { fileData, folderData } from "../../constants/DocumentListingCardsData";
import DropDown from "../../common/DropDown/DropDown";
import Drawers from "../../common/Drawers/Drawers";
import FilterPopUp from "../../common/FilterPopUp/FilterPopUp";
import Tables from "../../common/Tables/Tables";
import {
  documentListingListViewData,
  documentListingListViewTableHeadersData,
} from "../../constants/DocumentListingListData";
import DocumentListingListViewCards from "../DocumentListingListViewCards/DocumentListingListViewCards";
import { filterPopUpCommonOptions } from "../../constants/FilterPopUpCommonOptions";

const DocumentListing: React.FC = () => {
  const documnetListingCardData = [
    {
      label: "Active",
      content: (
        <DocumentListingCards folderData={folderData} fileData={fileData} />
      ),
    },
    {
      label: "Archive",
      content: (
        <DocumentListingCards folderData={folderData} fileData={fileData} />
      ),
    },
  ];
  const documentListingListData = [
    {
      label: "Active",
      content: (
        <Tables
          type="documentListingList"
          primary={true}
          data={documentListingListViewData}
          headerData={documentListingListViewTableHeadersData}
        />
      ),
    },
    {
      label: "Archive",
      content: (
        <Tables
          type="documentListingList"
          primary={true}
          data={documentListingListViewData}
          headerData={documentListingListViewTableHeadersData}
        />
      ),
    },
  ];
  const dropdownData = [
    { name: "Active", value: "active" },
    { name: "Archive", value: "archive" },
  ];
  const drawerOptions = [
    { value: "New Folder", key: "newFolder" },
    { value: "File Upload", key: "fileUpload" },
  ];
  const [value, setValue] = useState("active");
  const [activeTab, setActiveTab] = useState("tab1");
  const [isSlide, setIsSlide] = useState(false);
  const [tabsData, setTabsData] = useState(documnetListingCardData);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleTabClick = (tab: string) => {
    setActiveTab(tab);
    setIsSlide(tab === TAB2);
  };
  useEffect(() => {
    if (activeTab === TAB1) {
      setTabsData(documnetListingCardData);
    } else {
      setTabsData(documentListingListData);
    }
  }, [activeTab]);

  const handleDropDownChange = (event: SelectChangeEvent) => {
    setValue(event.target.value);
  };

  const isSmallScreen = useMediaQuery("(max-width: 1023px)");
  const [menuOpen, setMenuOpen] = React.useState(false);

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  const handleDrawerOptionClick = (key: string) => {
    if (key === "fileUpload" && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      console.log("Selected file:", files[0]);
    }
  };

  const renderComponent = () => {
    switch (value) {
      case "active":
        return (
          <>
            {activeTab === TAB1 ? (
              <DocumentListingCards
                folderData={folderData}
                fileData={fileData}
              />
            ) : (
              <DocumentListingListViewCards
                documentListingListViewData={documentListingListViewData}
              />
            )}
          </>
        );
      case "archive":
        return (
          <>
            {activeTab === TAB1 ? (
              <DocumentListingCards
                folderData={folderData}
                fileData={fileData}
              />
            ) : (
              <DocumentListingListViewCards
                documentListingListViewData={documentListingListViewData}
              />
            )}
          </>
        );

      default:
        return null;
    }
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [addAnchorEl, setAddAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const addOpen = Boolean(addAnchorEl);
  const [selectedKey, setSelectedKey] = useState(drawerOptions[0].key);
  const [filterSelectedKey, setFilterSelectedKey] = useState(
    filterPopUpCommonOptions[0].key
  );
  const handleAddClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAddAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAddAnchorEl(null);
  };

  const handleSelect = (key: string) => {
    setSelectedKey(key);
    if (key === "fileUpload" && fileInputRef.current) {
      fileInputRef.current.click();
    }
    handleClose();
  };
  const handleFilterSelect = (key: string) => {
    setFilterSelectedKey(key);
    handleClose();
  };
  return (
    <>
      <Box sx={DocumnetListingStyles.container}>
        <Box sx={DocumnetListingStyles.headingContainer}>
          <HeadingText heading={DOCUMNET_LISTING} />
          {!isSmallScreen && (
            <Box sx={DocumnetListingStyles.headingRightContainer}>
              <ImageAndText
                additionalClassName={
                  DocumnetListingStyles.imageAndTextFilterContainer
                }
                image={searchIcon}
                text={SEARCH}
              />
              <ImageAndText
                additionalClassName={
                  DocumnetListingStyles.imageAndTextFilterContainer
                }
                onhandleClick={handleClick}
                image={filterIcon}
                text={FILTER}
              />
              <Typography sx={DocumnetListingStyles.line}>|</Typography>
              <TabSwitch
                tab1Title={CARD_VIEW}
                tab2Title={LIST_VIEW}
                tab1ActiveImage={cardView}
                tab2ActiveImage={listViewWhite}
                tab2InActiveImage={listView}
                tab1InActiveImage={cardViewBlack}
                handleTabClick={handleTabClick}
                activeTab={activeTab}
                isSlide={isSlide}
              />
              <Typography sx={DocumnetListingStyles.line}>|</Typography>
              <CustomButton
                additionalClassName={DocumnetListingStyles.addNewButton}
                onHandleClick={handleAddClick}
                text={ADD_NEW}
              />
            </Box>
          )}
          {isSmallScreen && (
            <TabSwitch
              tab1Title={CARD_VIEW}
              tab2Title={LIST_VIEW}
              tab1ActiveImage={cardView}
              tab2ActiveImage={listViewWhite}
              tab2InActiveImage={listView}
              tab1InActiveImage={cardViewBlack}
              handleTabClick={handleTabClick}
              activeTab={activeTab}
              isSlide={isSlide}
            />
          )}
        </Box>

        {isSmallScreen ? (
          <Box sx={DocumnetListingStyles.activeContainer}>
            <DropDown
              data={dropdownData}
              placeholder={"Select"}
              selectedValue={value}
              onChange={handleDropDownChange}
              selectAdditionalClassName={DocumnetListingStyles.selectText}
              menuItemClassName={DocumnetListingStyles.menuItem}
              formControlAdditionalClassName={
                DocumnetListingStyles.dropDownContainer
              }
            />
            <Box sx={DocumnetListingStyles.imageAndTextContainer}>
              <ImageAndText
                additionalClassName={DocumnetListingStyles.imageContainer}
                image={filterIcon}
                text={FILTER}
                onhandleClick={handleClick}
              />
              <Typography sx={DocumnetListingStyles.line}>|</Typography>
              <ImageAndText
                onClick={handleMenuOpen}
                additionalClassName={DocumnetListingStyles.imageContainer}
                image={addnewIcon}
                text={"Add"}
              />
            </Box>
          </Box>
        ) : (
          <BasicTabs
            additionalLabelClass={DocumnetListingStyles.tabsText}
            tabs={tabsData}
          />
        )}
        {isSmallScreen && <Box>{renderComponent()}</Box>}
      </Box>

      <Drawers
        type="secondary"
        anchor="bottom"
        open={menuOpen}
        additionalClass={DocumnetListingStyles.drawer}
        toggleDrawer={handleMenuOpen}
      >
        <Box>
          <HeadingText
            additionalHeadingClass={DocumnetListingStyles.drawertextHeading}
            heading={"Add"}
          />
          <Box sx={DocumnetListingStyles.optionsContainer}>
            {drawerOptions.map((option, index) => (
              <Typography
                key={option.key}
                sx={
                  index === drawerOptions.length - 1
                    ? DocumnetListingStyles.lastText
                    : DocumnetListingStyles.drawerTexts
                }
                onClick={() => handleDrawerOptionClick(option.key)}
              >
                {option.value}
              </Typography>
            ))}
          </Box>
        </Box>
      </Drawers>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <FilterPopUp
        options={drawerOptions}
        selectedKey={selectedKey}
        onSelect={handleSelect}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      />
      <FilterPopUp
        options={filterPopUpCommonOptions}
        selectedKey={filterSelectedKey}
        onSelect={handleFilterSelect}
        anchorEl={addAnchorEl}
        open={addOpen}
        onClose={handleClose}
      />
    </>
  );
};

export default DocumentListing;
