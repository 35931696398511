import React, { useCallback, useEffect, useState, useMemo } from "react";
import { Box, Card, Typography, useMediaQuery, Tabs, Tab } from "@mui/material";
import ActivityCard from "./Policies/ActivityCard/ActivityCard";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useParams } from "react-router-dom";
import { RootState } from "../../../../store/store";
import BillingActivityCard from "./Billing/BillingActivityCard/BillingActivityCard";
import {
  IDocument,
  IDocumentContentRequest,
} from "../../../../models/user/policyDetails/Document";
import { getBufferData, isFeatureAvailable } from "../../../../utils/Utility";
import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import moment from "moment";
import CustomTimeline from "../../../../common/CustomTimeline/CustomTimeline";
import { getClaimsData } from "../../../Dashboard/DashboardAction";
import {
  IBillingTransaction,
  IClaimId,
  IPolicyTransaction,
} from "../../../../models/user/policyDetails/PolicyDetails";
import ClaimActivityCard from "./Claims/ClaimActivityCard/ClaimActivityCard";
import {
  GetInsuranceDocumentContent,
  GetPolicyActivities,
  GetPolicyClaimsTransactions,
} from "../../../../store/user/policyDetails/policyDetailsActions";
import {
  IClaim,
  IClaimTransaction,
} from "../../../../models/user/dashboard/Dashboard";
import { activityTabs, dateFormat } from "../../../../constants/Constants";
import { ActivityStyles } from "./ActivityStyles";
import Policies from "./Policies/Policies";
import Claims from "./Claims/Claims";
import Billing from "./Billing/Billing";

const Activity: React.FC = () => {
  const { features } = useAppSelector((state: RootState) => state.features);

  const [value, setValue] = useState("lob_pa_policies_activity");

  const tabOptions = useMemo(
    () =>
      activityTabs.filter((tab) => {
        switch (tab.label) {
          case "Policy": {
            return isFeatureAvailable({
              featureCodes: ["lob_pa_policies_activity"],
              features,
            });
          }
          case "Claims": {
            return isFeatureAvailable({
              featureCodes: ["lob_pa_claims"],
              features,
            });
          }
          case "Billing": {
            return isFeatureAvailable({
              featureCodes: ["lob_pa_billing"],
              features,
            });
          }
          default:
            return true;
        }
      }),
    [features]
  );

  const tab = useMemo(() => {
    switch (value) {
      case "lob_pa_policies_activity":
        return <Policies />;
      case "lob_pa_claims":
        return <Claims />;
      case "lob_pa_billing":
        return <Billing />;
    }
  }, [value]);

  return (
    <Box sx={ActivityStyles.activity}>
      <Tabs
        // sx={{
        //   borderBottom: "1px solid #94ABB6",
        // }}
        onChange={(e, value) => setValue(value)}
        value={value}
        allowScrollButtonsMobile
      >
        {tabOptions.map((tab) => (
          <Tab
            key={tab.code}
            label={tab.label}
            value={tab.code}
            sx={{
              color: "#383D4E",
              fontFamily: "Playfair Display",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "20px",
              textTransform: "none",
            }}
          />
        ))}
      </Tabs>
      <Card sx={ActivityStyles.container}>{tab}</Card>
    </Box>
  );
};

export default Activity;
