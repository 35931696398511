import latestNewsIcon from "../assets/svgs/latest-news.svg";
export const dashboardJson = {
  customerData: [
    { heading: 186, subheading: "Overdue Policies" },
    { heading: 55, subheading: "Expired Policies" },
    { heading: 80, subheading: "Renewed Policies" },
  ],
  locationComparisonData: [
    { uv: 18, name: "Location 1" },
    { uv: 21, name: "Location 2" },
    { uv: 29, name: "Location 3" },
  ],
  stackedBarChartData: [
    { name: "Week 1", Open: 2, "In Progress": 3, Closed: 4 },
    { name: "Week 2", Open: 2, "In Progress": 5, Closed: 6 },
    { name: "Week 3", Open: 3, "In Progress": 6, Closed: 7 },
    { name: "Week 4", Open: 4, "In Progress": 11, Closed: 4 },
  ],
  existingBusinessData: [
    { heading: "1,000", subheading: "Total Policies Sold" },
    { heading: "$1,200,000", subheading: "Total Premium Written" },
    { heading: "$100,000", subheading: "Total Claims Raised" },
    { heading: "$15,000", subheading: "Total Commission Earned" },
    { heading: "95", subheading: "Retention Ratio", percentage: true },
    { heading: "5", subheading: "Loss Ratio", percentage: true },
  ],
  policiesData: [
    {
      number: "100",
      description: "Auto Insurance",
      image: "policy",
    },
    {
      number: "80",
      description: "Homeowners Insurance",
      image: "policy",
    },
    {
      number: "50",
      description: "Renters Insurance",
      image: "policy",
    },
  ],
  latestNewsData: [
    {
      description:
        "We're excited to announce the launch of our new mobile app, Insure Ease. Now you can manage your policies, file claims, and access your insurance cards right from your smartphone. Download it today from your app store for a more convenient insurance experience.",
      date: "10th Oct",
      time: "04:30 pm",
      image: latestNewsIcon,
      name: "ACME Insurance",
    },
    {
      description:
        "Hurricane season is approaching. As part of our commitment to your safety, we're offering free home inspections to all our homeowners insurance policyholders in coastal areas. Schedule yours before August 15th to ensure your property is prepared for severe weather.",
      date: "10th Oct",
      time: "05:30 pm",
      image: latestNewsIcon,
      name: "ACME Insurance",
    },
  ],
  newBusinessData: [
    { heading: 186, subheading: "New Quotes" },
    { heading: 55, subheading: "In Progress" },
    { heading: 80, subheading: "Issued" },
    { heading: "3:1", subheading: "Conversion Ratio" },
  ],
  premiumWrittenData: [
    { name: "Week 1", uv: 8000 },
    { name: "Week 2", uv: 11000 },
    { name: "Week 3", uv: 14000 },
    { name: "Week 4", uv: 16000 },
  ],
  policiesSoldData: [
    { name: "Week 1", uv: 18 },
    { name: "Week 2", uv: 22 },
    { name: "Week 3", uv: 28 },
    { name: "Week 4", uv: 32 },
  ],
  lobCompaniesData: [
    { name: "Umbrella Insurance", uv: 8000 },
    { name: "Renters Insurance", uv: 11000 },
    { name: "Auto Insurance", uv: 14000 },
    { name: "Homeowners Insurance", uv: 16000 },
  ],
  comissionTrackingData: [
    { day: "01st Jul", value: 30000 },
    { day: "02nd Jul", value: 30000 },
    { day: "03rd Jul", value: 35000 },
    { day: "04th Jul", value: 35000 },
    { day: "05th Jul", value: 40000 },
    { day: "06th Jul", value: 45000 },
    { day: "07th Jul", value: 50000 },
    { day: "08th Jul", value: 55000 },
    { day: "09th Jul", value: 55000 },
    { day: "10th Jul", value: 60000 },
    { day: "11th Jul", value: 70000 },
    { day: "12th Jul", value: 75000 },
    { day: "13th Jul", value: 80000 },
    { day: "14th Jul", value: 80000 },
    { day: "15th Jul", value: 90000 },
    { day: "16th Jul", value: 95000 },
    { day: "17th Jul", value: 100000 },
    { day: "18th Jul", value: 105000 },
    { day: "19th Jul", value: 105000 },
    { day: "20th Jul", value: 110000 },
    { day: "21st Jul", value: 110000 },
    { day: "22nd Jul", value: 110000 },
    { day: "23rd Jul", value: 115000 },
    { day: "24th Jul", value: 120000 },
    { day: "25th Jul", value: 120000 },
    { day: "26th Jul", value: 120000 },
    { day: "27th Jul", value: 125000 },
    { day: "28th Jul", value: 125000 },
    { day: "29th Jul", value: 130000 },
    { day: "30th Jul", value: 140000 },
    { day: "31st Jul", value: 140000 },
  ],
  topPerformingAgentsData: [
    { number: "$38,250", description: "Alex Ramirez", image: "agent" },
    { number: "$37,800", description: "Emily Carter", image: "agent" },
    { number: "$36,550", description: "Michael Johnson", image: "agent" },
  ],
};
