import React from "react";

import BasicTabs from "../../common/BasicTabs/BasicTabs";
import greenIcon from "../../assets/svgs/green-icon.svg";
import redIcon from "../../assets/svgs/red-icon.svg";
import yellowIcon from "../../assets/svgs/yellow-icon.svg";
import Tables from "../../common/Tables/Tables";
import { Box } from "@mui/material";
import { MyTasksTableStyles } from "./MyTasksTableStyles";
import {
  HIGH_PRIORITY_TASKS,
  LOW_PRIORITY_TASKS,
  MEDIUM_PRIORITY_TASKS,
  TOTAL_TASKS,
} from "../../constants";
interface ClientDetailsProps {
  clientName: string;
  clientEmail: string;
  clientPhone: string;
}
export interface TasksDataProps {
  timeStamp: {
    date: string;
    time: string;
  };
  task: string;
  clientDetails: ClientDetailsProps;
  policy: string;
  priority: string;
  status: string;
}
interface TasksTableDataProps {
  tasksData: TasksDataProps[];
  tasksTableHeadingsData: string[];
  activeTab: string;
}
const MyTasksTable: React.FC<TasksTableDataProps> = ({
  tasksData,
  tasksTableHeadingsData,
  activeTab,
}) => {
  const tabData = [
    {
      label: TOTAL_TASKS,
      content: (
        <Tables
          headerData={tasksTableHeadingsData}
          type="myTasksTable"
          data={tasksData}
        />
      ),
      secondaryLabel: "30",
    },
    {
      label: HIGH_PRIORITY_TASKS,
      content: (
        <Tables
          headerData={tasksTableHeadingsData}
          type="myTasksTable"
          data={tasksData}
        />
      ),
      secondaryLabel: "08",
      imageUrl: redIcon,
      activeImageUrl: redIcon,
    },
    {
      label: MEDIUM_PRIORITY_TASKS,
      content: (
        <Tables
          headerData={tasksTableHeadingsData}
          type="myTasksTable"
          data={tasksData}
        />
      ),
      secondaryLabel: "10",
      imageUrl: yellowIcon,
      activeImageUrl: yellowIcon,
    },
    {
      label: LOW_PRIORITY_TASKS,
      content: (
        <Tables
          headerData={tasksTableHeadingsData}
          type="myTasksTable"
          data={tasksData}
        />
      ),
      secondaryLabel: "12",
      imageUrl: greenIcon,
      activeImageUrl: greenIcon,
    },
  ];

  return (
    <>
      <Box sx={MyTasksTableStyles.container}>
        <BasicTabs tabs={tabData} />
      </Box>
    </>
  );
};

export default MyTasksTable;
