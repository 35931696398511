import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { RootState } from "../../../../../store/store";
import { GetBillingTransactions } from "../../../../../store/user/policyDetails/policyDetailsActions";
import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import CustomTimeline from "../../../../../common/CustomTimeline/CustomTimeline";
import moment from "moment";
import { dateFormat } from "../../../../../constants/Constants";
import BillingActivityCard from "./BillingActivityCard/BillingActivityCard";
import { Typography, useMediaQuery } from "@mui/material";

const Billing = () => {
  const dispatch = useAppDispatch();
  const { policyDetailsHeader, billingTransactions } = useAppSelector(
    (state: RootState) => state.policyDetails
  );

  const isMobile = useMediaQuery("(max-width:1024px)");

  useEffect(() => {
    if (policyDetailsHeader && policyDetailsHeader.accountId) {
      dispatch(
        GetBillingTransactions({ accountId: policyDetailsHeader?.accountId })
      );
    }
  }, [dispatch, policyDetailsHeader]);

  return billingTransactions.length > 0 ? (
    <Timeline
      sx={{
        padding: "0px",
        [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.1 },
      }}
    >
      {[...billingTransactions]
        .sort(
          (a, b) =>
            new Date(b.transactionDate).getTime() -
            new Date(a.transactionDate).getTime()
        )
        .map((transaction, index) => (
          <CustomTimeline
            key={`policy-activity-${index}`}
            oppositeContent={moment(transaction.transactionDate).format(
              dateFormat
            )}
            oppositeContentStyle={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            dotStyle={{
              display: "flex",
              alignItems: "center",
            }}
            children={
              <BillingActivityCard
                transaction={transaction}
                isLast={
                  index ===
                  (billingTransactions
                    ? isMobile
                      ? billingTransactions.length - 2
                      : billingTransactions.length - 1
                    : false)
                }
              />
            }
          />
        ))}
    </Timeline>
  ) : (
    <Typography>No Transactions Found</Typography>
  );
};

export default Billing;
