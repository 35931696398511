import { Box, Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { IPaymentSuccess } from "../../models/user/payment/Payment";
import paymentStyle from "./PaymentStyle";

const PaymentSuccess: React.FC = () => {
  const { responseData } = useParams();
  const navigate = useNavigate();
  if (!responseData) {
    navigate("/");
  }
  let data: IPaymentSuccess | null = null;
  if (responseData) {
    data = JSON.parse(atob(responseData));
  }
  if (!data) {
    navigate("/");
  }

  const handleHome = () => navigate("/customer/dashboard");

  return (
    <Box sx={paymentStyle.pSuccesMain}>
      <Box sx={paymentStyle.pSuccessMai2}>
        <Box sx={paymentStyle.pSuccessMsgSec}>
          <Box>
            <CheckCircleOutlineIcon
              fontSize="large"
              sx={paymentStyle.pSuccessIcon}
            />
          </Box>
          <Box sx={{ marginTop: "0.5rem" }}>
            <Typography sx={paymentStyle.pSuccessMsg}>
              {" "}
              Payment Successful{" "}
            </Typography>
          </Box>
        </Box>
        <Box sx={paymentStyle.pSuccessSecBox}>
          <Box sx={{ width: "50%" }}>
            <Typography sx={paymentStyle.textFont}>Paid Amount </Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography sx={paymentStyle.textFont}>
              ${data?.totalAmount.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box sx={paymentStyle.pSuccessSecBox}>
          <Box sx={{ width: "50%" }}>
            <Typography sx={paymentStyle.textFont}>Policy Number</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography sx={paymentStyle.textFont}>
              {data?.carrierPolicyNo}
            </Typography>
          </Box>
        </Box>
        <Box sx={paymentStyle.pSuccessSecBox}>
          <Box sx={{ width: "50%" }}>
            <Typography sx={paymentStyle.textFont}>Reference Number</Typography>
          </Box>
          <Box sx={{ width: "50%" }}>
            <Typography sx={paymentStyle.transactionId}>
              {data?.transactionId}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ...paymentStyle.paySection }}>
          <Button
            onClick={handleHome}
            type="button"
            name="home"
            sx={{ ...paymentStyle.payBtn }}
          >
            Home
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentSuccess;
