import {
  Box,
  Button,
  Card,
  Divider,
  Drawer,
  IconButton,
  Link,
  Popover,
  Typography,
} from "@mui/material";
import ClaimCardStyles from "./CalimCardStyles";
import {
  IClaim,
  IClaimTransaction,
  IGetClaimTransactionDocumentContentRequestParams,
} from "../../../../models/user/dashboard/Dashboard";
import { FC, useCallback, useEffect, useState } from "react";
import moment from "moment";
import { dateFormat } from "../../../../constants/Constants";
import { Timeline, timelineOppositeContentClasses } from "@mui/lab";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  GetClaimTransactionDocumentContent,
  GetClaimTransactions,
} from "../../DashboardAction";
import { RootState } from "../../../../store/store";
import Document from "../../../PolicyDetails/policyTabComponents/Documents/Document/Document";
import { IDocument } from "../../../../models/user/policyDetails/Document";
import { getBufferData, isFeatureAvailable } from "../../../../utils/Utility";
import { setClaimTransactionDocumentContent } from "../../DashboardSlice";
import CustomTimeline from "../../../../common/CustomTimeline/CustomTimeline";
import CustomDialog from "../../../../common/CustomDialog/CustomDialog";
import CustomIcon from "../../../../common/CustomIcon/CustomIcon";
import { CloseOutlined } from "@mui/icons-material";

interface props {
  claim: IClaim;
}

const ClaimCard: FC<props> = ({ claim }) => {
  const dispatch = useAppDispatch();

  const { claimTransactions, claimDocuments, claimTransactionDocumentContent } =
    useAppSelector((state: RootState) => state.dashboard);
  const { isSuccess, calledReducerType, isError } = useAppSelector(
    (store: RootState) => store.common
  );
  const { features } = useAppSelector((state: RootState) => state.features);

  const [currentClickFile, setCurrentClickFile] = useState<IDocument | null>(
    null
  );
  const [claimActivityDialogOpen, setClaimActivityDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const getIcon = (lineCode: string) => {
    switch (lineCode) {
      case "1":
        return <CustomIcon iconName="Car" />;

      case "2":
        return <CustomIcon iconName="Home" />;
    }
  };

  const handleContactLinkClick = useCallback(
    (event: any) => setAnchorEl(event.currentTarget),
    []
  );

  const handleClaimViewDetails = (claimId: string) => {
    dispatch(GetClaimTransactions({ claimId })).then(() =>
      setClaimActivityDialogOpen(true)
    );
  };

  const handleFileClick = (documentData: IDocument) => {
    dispatch(setClaimTransactionDocumentContent(null));
    setCurrentClickFile(documentData);
    const requestParams: IGetClaimTransactionDocumentContentRequestParams = {
      claimId: claim.claimId,
      documentId: documentData?.documentId,
    };
    dispatch(GetClaimTransactionDocumentContent(requestParams));
  };

  useEffect(() => {
    if (
      isSuccess &&
      calledReducerType === "dashboard/GetClaimTransactionDocumentContent" &&
      claimTransactionDocumentContent &&
      currentClickFile
    ) {
      const bufferArray = getBufferData(claimTransactionDocumentContent);
      const url = URL.createObjectURL(
        new Blob([bufferArray], { type: "application/pdf" })
      );

      const downloadLink = document.createElement("a");
      downloadLink.download = `${currentClickFile?.name}-${currentClickFile?.addDate}-${currentClickFile?.addTime}.pdf`;
      downloadLink.href = url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }, [
    calledReducerType,
    isSuccess,
    claimTransactionDocumentContent,
    currentClickFile,
  ]);

  useEffect(() => {
    if (
      isError &&
      calledReducerType === "dashboard/GetClaimTransactionDocumentContent"
    ) {
      alert(`Error in downloading document`);
    }
  }, [calledReducerType, isError]);

  return (
    <>
      <Card sx={ClaimCardStyles.claimsCard}>
        {claim.risk ? (
          <Box sx={ClaimCardStyles.containerOne}>
            <Typography sx={ClaimCardStyles.claimsCardItemHeading}>
              {claim.lineCode === "1"
                ? `${claim.risk.manufactureYr}-${claim.risk.make}-${claim.risk.model}`
                : `${claim.risk.make}`}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "20px",
            width: "100%",
            "@media (max-width:600px)": { flexDirection: "column" },
          }}
        >
          {getIcon(claim.lineCode)}
          <Box sx={ClaimCardStyles.containerTwo}>
            <Box sx={ClaimCardStyles.claimPolicyName}>
              <Typography sx={ClaimCardStyles.claimsCardItemHeading}>
                {claim.carrierPolicyNo}
              </Typography>
              <Typography sx={ClaimCardStyles.claimsCartItemContent}>
                Policy Number
              </Typography>
            </Box>
            <Box
              sx={{
                "@media (max-width:1200px)": {
                  gridArea: "claimNumber",
                },
              }}
            >
              <Typography sx={ClaimCardStyles.claimsCardItemHeading}>
                {claim.carrierClaimNo}
              </Typography>
              <Typography sx={ClaimCardStyles.claimsCartItemContent}>
                Claim Number
              </Typography>
            </Box>
            <Box
              sx={{
                "@media (max-width:1200px)": {
                  gridArea: "verification",
                },
              }}
            >
              <Typography sx={ClaimCardStyles.claimsCardItemHeading}>
                {claim.carrierClaimStatus}
              </Typography>
              <Typography sx={ClaimCardStyles.claimsCartItemContent}>
                Claim Status
              </Typography>
            </Box>
            <Box
              sx={{
                "@media (max-width:1200px)": {
                  gridArea: "updatedOn",
                },
              }}
            >
              <Typography sx={ClaimCardStyles.claimsCardItemHeading}>
                {moment(claim.updateDate).format(dateFormat)}
              </Typography>
              <Typography sx={ClaimCardStyles.claimsCartItemContent}>
                Updated On
              </Typography>
            </Box>
            <Box
              sx={{
                "@media (max-width:1200px)": {
                  gridArea: "claimInitiated",
                },
              }}
            >
              <Typography sx={ClaimCardStyles.claimsCardItemHeading}>
                {moment(claim.initiatedDate).format(dateFormat)}
              </Typography>
              <Typography sx={ClaimCardStyles.claimsCartItemContent}>
                Claim Initiated
              </Typography>
            </Box>
            <Box
              sx={{
                "@media (max-width:1200px)": {
                  gridArea: "contactPerson",
                },
              }}
            >
              <Link
                sx={{
                  ...ClaimCardStyles.claimsCardItemHeading,
                  cursor: "pointer",
                }}
                onClick={(event) => handleContactLinkClick(event)}
              >
                {claim?.adjusterId &&
                // claim.carrierClaimStatus !== "Closed" &&
                claim.adjusterDetails &&
                claim.adjusterDetails.nameInfo
                  ? claim?.adjusterDetails?.nameInfo
                  : claim.adjusterDetails && claim.adjusterDetails.nameInfo
                  ? claim.adjusterDetails.nameInfo.split(",")[1]
                  : ""}
              </Link>
              {claim.adjusterId ? (
                <Typography sx={ClaimCardStyles.claimsCartItemContent}>
                  Contact Person
                </Typography>
              ) : (
                <></>
              )}
            </Box>
            {isFeatureAvailable({
              featureCodes: ["lob_pa_claims_track_claim"],
              features,
            }) ? (
              <Box sx={ClaimCardStyles.claimsCardButtonContainer}>
                <Button
                  variant="contained"
                  onClick={() => handleClaimViewDetails(claim.claimId)}
                >
                  {claim.carrierClaimStatus === "Closed"
                    ? "View Details"
                    : "Track Your Claim"}
                </Button>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Card>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={ClaimCardStyles.contactPopoverContent}>
          <Box>
            <Typography sx={ClaimCardStyles.claimsCartItemContent}>
              Name
            </Typography>
            <Typography sx={ClaimCardStyles.claimsCardItemHeading}>
              {claim && claim.adjusterDetails
                ? claim.adjusterDetails.nameInfo
                : ""}
            </Typography>
          </Box>
          {claim?.adjusterDetails?.emailInfo ? (
            <Box>
              <Typography sx={ClaimCardStyles.claimsCartItemContent}>
                Email Id
              </Typography>
              <Typography sx={ClaimCardStyles.claimsCardItemHeading}>
                {claim && claim.adjusterDetails
                  ? claim.adjusterDetails.emailInfo
                  : ""}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          <Box>
            <Typography sx={ClaimCardStyles.claimsCartItemContent}>
              Mobile Number
            </Typography>
            <Typography sx={ClaimCardStyles.claimsCardItemHeading}>
              {claim?.adjusterDetails?.phoneInfo.length > 0
                ? claim?.adjusterDetails?.phoneInfo
                : "-"}
            </Typography>
          </Box>
        </Box>
      </Popover>

      <Drawer
        PaperProps={{
          sx: {
            padding: "20px",
            width: "50%",
            "@media (max-width:768px)": {
              width: "100%",
            },
          },
        }}
        open={claimActivityDialogOpen}
        anchor="right"
        onClose={() => setClaimActivityDialogOpen(false)}
      >
        <Box sx={{ display: "grid", gap: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                color: "#1D252B",
                fontSize: "18px",
                fontFamily: "Noto Sans",
                fontWeight: "300",
              }}
            >
              {claim.carrierClaimNo} - {claim.carrierClaimStatus}
            </Typography>
            <IconButton onClick={() => setClaimActivityDialogOpen(false)}>
              <CloseOutlined />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ display: "grid", gap: "10px" }}>
            <Typography
              sx={{
                color: "#383D4E",
                fontSize: "20px",
                fontWeight: 400,
                fontFamily: "Playfair Display",
              }}
            >
              Transactions
            </Typography>
            {claimTransactions.length > 0 ? (
              <Timeline
                sx={{
                  [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                  },
                }}
              >
                {claimTransactions.map((item: IClaimTransaction, index) => (
                  <CustomTimeline
                    key={`claim-transaction-${index}`}
                    oppositeContent={
                      <Box
                        sx={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Typography>
                          {moment(new Date(item.transactionDate)).format(
                            dateFormat
                          )}
                        </Typography>
                      </Box>
                    }
                    oppositeContentStyle={{
                      color: "#1D252B",
                      fontSize: "18px",
                      fontFamily: "Noto Sans",
                      fontWeight: "300",
                      "@media(max-width:599px)": {
                        fontSize: "0.8rem",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontFamily: "Noto Sans",
                      },
                      "@media (min-width: 1025px) and (max-width: 1366px)": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#1D252B",
                          fontSize: "18px",
                          fontFamily: "Noto Sans",
                          fontWeight: "400",
                          textOverflow: "ellipsis",
                          "@media (max-width:599px)": {
                            fontSize: "0.8rem",
                          },
                        }}
                      >
                        {item.transactionDescription}
                      </Typography>
                      {item.adjusterDetails ? (
                        <Link
                          sx={{
                            ...ClaimCardStyles.claimsCartItemContent,
                            cursor: "pointer",
                          }}
                          onClick={handleContactLinkClick}
                        >
                          Contact Person
                        </Link>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </CustomTimeline>
                ))}
              </Timeline>
            ) : (
              <Typography
                sx={{
                  color: "#1D252B",
                  fontSize: "18px",
                  fontFamily: "Noto Sans",
                  fontWeight: "300",
                  "@media(max-width:599px)": {
                    fontSize: "14px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontFamily: '"Noto Sans"',
                  },
                  "@media (min-width: 1025px) and (max-width: 1366px)": {
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  },
                }}
              >
                No transactions found
              </Typography>
            )}
          </Box>
          {isFeatureAvailable({
            featureCodes: ["lob_pa_claims_claim_documents"],
            features,
          }) ? (
            <Box sx={{ display: "grid", gap: "10px" }}>
              <Typography
                sx={{
                  color: "#383D4E",
                  fontSize: "20px",
                  fontWeight: 400,
                  fontFamily: "Playfair Display",
                }}
              >
                Documents
              </Typography>
              <Box>
                {false && claimDocuments.length > 0 ? (
                  claimDocuments.map((document, index) => (
                    <Document
                      key={`doc-list-${index}`}
                      documentData={document}
                      handleFileClick={() => handleFileClick(document)}
                      view={false}
                    />
                  ))
                ) : (
                  <Typography
                    sx={{
                      color: "#1D252B",
                      fontSize: "18px",
                      fontFamily: "Noto Sans",
                      fontWeight: "300",
                      "@media(max-width:599px)": {
                        fontSize: "14px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontFamily: '"Noto Sans"',
                      },
                      "@media (min-width: 1025px) and (max-width: 1366px)": {
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      },
                    }}
                  >
                    No Documents Available
                  </Typography>
                )}
              </Box>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      </Drawer>
    </>
  );
};

export default ClaimCard;
